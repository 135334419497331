.circle {
  position: absolute;
  padding: 0;
  margin: 1em auto;
  width: calc(100% + 55px);
  height: calc(100% + 55px);
  border-radius: 50%;
  list-style: none;
  overflow: hidden;
  z-index: -1;
}
.li-btn {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50%;
  transform-origin: 0% 100%;
}
.li-btn {
  background-color: rgb(163, 225, 211);
  border: 1px solid rgb(230, 230, 230);
}
.li-btn1 {
  transform: rotate(180deg) skewY(-60deg);
}
.li-btn2 {
  transform: rotate(210deg) skewY(-60deg);
}
.li-btn3 {
  transform: rotate(240deg) skewY(-60deg);
}


