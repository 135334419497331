.react-flow__node-default,
.react-flow__node-group,
.react-flow__node-input,
.react-flow__node-output {
  border-radius: 50%;
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.react-flow__handle {
  height: 0;
  width: 0;
}

.react-flow__handle.connectable {
  cursor: default;
  visibility: hidden;
}

.react-flow__attribution {
  display: none;
}
/* //// */
.floatingedges {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.floatingedges .react-flow__handle {
  opacity: 0;
}
